import { Navigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'
import { RouteProps } from 'react-router/dist/lib/components'
import React from 'react'

type Props = {
    children: React.ReactElement[] | React.ReactElement
}

export const ProtectedRoute = ({ children }: Props) => {
    const { authToken } = useAuth()

    if (!authToken) {
        // user is not authenticated
        return <Navigate to="/login" />
    }

    return <>{children}</>
}
