import React, { useCallback, useState } from 'react'
import { TopMenu } from '../TopMenu/TopMenu'
import { CaseSelect } from '../CaseSelect/CaseSelect'
import DateNavigator from '../DateNavigator/DateNavigator'
import { SimpleDate } from 'simple-date-ts'
import { VoiceNoteDashboard } from '../VoiceNoteDashboard/VoiceNoteDashboard'
import { AnswersDashboard } from '../AnswersDashboard/AnswersDashboard'

type Props = {
    authToken: string | null
    setAuthToken: (token: string | null) => void
}

export const Dashboard: React.FC<Props> = () => {
    const [date, setDate] = useState(SimpleDate.today())
    const [caseId, setCaseId] = useState('')

    const onSelectCase = useCallback(async (caseId: string) => {
        setCaseId(caseId)
    }, [])

    return (
        <>
            <TopMenu />

            <div className="content">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ flex: 1 }}></div>

                    <div>
                        <CaseSelect onSelect={onSelectCase} />
                    </div>
                    <div>
                        <DateNavigator date={date} setDate={setDate} />
                    </div>

                    <div style={{ flex: 1 }} />
                </div>

                <h2 style={{ color: '#3F3C76' }}>Voice Notes</h2>

                <VoiceNoteDashboard caseId={caseId} date={date} />

                <br />
                <br />

                <h2 style={{ color: '#3F3C76' }}>Answers</h2>

                <AnswersDashboard caseId={caseId} date={date} />
            </div>
        </>
    )
}
