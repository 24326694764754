import {gql, useMutation} from '@apollo/react-hooks'
import GraphQlHookProps from './GraphQlHookProps'
import { DefaultOutput, Note } from '../gql/graphql'

export const useRemoveCaseAndRelatedDataByOwnerUserIdMutation = ({onCompleted, onError}: GraphQlHookProps<{removeCaseAndRelatedDataByOwnerUserId?: DefaultOutput}>) => {
    const mutation = gql`
        mutation removeCaseAndRelatedDataByOwnerUserId($userId: String!) {
            removeCaseAndRelatedDataByOwnerUserId(userId: $userId) {
                message
            }
        }
    `

    return useMutation(mutation, {
        onCompleted,
        onError,
    })
}
