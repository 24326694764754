// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.voiceNotesContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}
.voiceNoteNothingFound {
    flex: 1 1;
    font-size: 20px;
    min-height: 200px;
    display: flex;
    align-content: center;
    align-items: center;
    /*justify-content: center;*/
    justify-items: center;
    margin-left: 20px;
}

.voiceNoteContainer {
    min-width: 200px;
    min-height: 200px;
    max-width: 500px;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    padding: 10px;
    gap: 20px;
}

.voiceNoteHeader {
    gap: 16px;
    display: flex;
    flex-direction: row;
}

.voiceNoteAuthorPhotoPlaceholder {
    background: lightgray;
}

.voiceNoteAuthorPhoto {
    width: 40px;
    height: 40px;
    border-radius: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/VoiceNoteDashboard/VoiceNoteDashboard.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,eAAe;AACnB;AACA;IACI,SAAO;IACP,eAAe;IACf,iBAAiB;IACjB,aAAa;IACb,qBAAqB;IACrB,mBAAmB;IACnB,2BAA2B;IAC3B,qBAAqB;IACrB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;IACb,SAAS;AACb;;AAEA;IACI,SAAS;IACT,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB","sourcesContent":[".voiceNotesContainer {\n    display: flex;\n    gap: 10px;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n.voiceNoteNothingFound {\n    flex: 1;\n    font-size: 20px;\n    min-height: 200px;\n    display: flex;\n    align-content: center;\n    align-items: center;\n    /*justify-content: center;*/\n    justify-items: center;\n    margin-left: 20px;\n}\n\n.voiceNoteContainer {\n    min-width: 200px;\n    min-height: 200px;\n    max-width: 500px;\n    border-style: solid;\n    border-color: lightgray;\n    border-width: 1px;\n    padding: 10px;\n    gap: 20px;\n}\n\n.voiceNoteHeader {\n    gap: 16px;\n    display: flex;\n    flex-direction: row;\n}\n\n.voiceNoteAuthorPhotoPlaceholder {\n    background: lightgray;\n}\n\n.voiceNoteAuthorPhoto {\n    width: 40px;\n    height: 40px;\n    border-radius: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
