import React, { createContext, useContext } from 'react'
import useLocalStorage from 'use-local-storage'

type AuthContextType = {
    authToken?: string
    setAuthToken: (s: string | undefined) => void
}

const AuthContext = createContext<AuthContextType>({
    setAuthToken: (s: string | undefined) => {},
})

export const AuthProvider = ({ children }: { children: React.ReactElement[] | React.ReactElement }) => {
    const [authToken, setAuthToken] = useLocalStorage<string | undefined>('authToken', undefined)

    const value: AuthContextType = {
        authToken,
        setAuthToken,
    }

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
    return useContext(AuthContext)
}
