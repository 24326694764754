import {gql, useMutation} from '@apollo/react-hooks'
import GraphQlHookProps from './GraphQlHookProps'
import { DefaultOutput, Note } from '../gql/graphql'

export const useRemoveUserMutation = ({onCompleted, onError}: GraphQlHookProps<{removeUser?: DefaultOutput}>) => {
    const mutation = gql`
        mutation removeUser($id: String!) {
            removeUser(id: $id) {
                message
            }
        }
    `

    return useMutation(mutation, {
        onCompleted,
        onError,
    })
}
