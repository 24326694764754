import React, { useState } from 'react'
import './App.css'
import SignInForm from './components/SignInForm'
import { UserList } from './components/UserList/UserList'
import { CaseList } from './components/CaseList/CaseList'
import 'bootstrap/dist/css/bootstrap.min.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Dashboard } from './components/Dashboard/Dashboard'
import { ProtectedRoute } from './components/ProtectedRoute'
import { AuthProvider } from './hooks/useAuth'

function App() {
    const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'))

    return (
        <div className="App">
            <AuthProvider>
                <BrowserRouter>
                    <Routes>
                        <Route path="/login" element={<SignInForm />} />
                        <Route
                            path="/"
                            element={
                                <ProtectedRoute>
                                    <Dashboard authToken={authToken} setAuthToken={setAuthToken} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/cases"
                            element={
                                <ProtectedRoute>
                                    <CaseList authToken={authToken} setAuthToken={setAuthToken} />
                                </ProtectedRoute>
                            }
                        />
                        <Route
                            path="/users"
                            element={
                                <ProtectedRoute>
                                    <UserList authToken={authToken} setAuthToken={setAuthToken} />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </BrowserRouter>
            </AuthProvider>
        </div>
    )
}

export default App
