import React, { useState } from 'react'
import { useCasesQuery } from '../../api/useCasesQuery'
import { Case } from '../../gql/graphql'
import { TopMenu } from '../TopMenu/TopMenu'
import { useRemoveCaseAndRelatedDataMutation } from '../../api/useRemoveCaseAndRelatedDataMutation'

type Props = {
    authToken: string | null
    setAuthToken: (token: string | null) => void
}

export const CaseList: React.FC<Props> = () => {
    const { data, loading, refetch } = useCasesQuery({})
    const [reloadParam, setReloadParam] = useState(0)

    const [removeCase] = useRemoveCaseAndRelatedDataMutation({
        variables: { reloadParam },
    })

    async function remove(_id: string, name: string) {
        if (!window.confirm(`Are you sure you want to delete case ${name}?`)) {
            return
        }

        await removeCase({ variables: { id: _id } })
        setReloadParam(reloadParam + 1) // forcing reload, otherwise apollo won't reload anything
        await refetch()
    }

    return (
        <>
            <TopMenu />

            <div className="content">
                <h1>Cases</h1>

                <table className={'table table-bordered'}>
                    <tbody>
                        <tr>
                            <th>Name</th>
                            <th>Owner Id</th>
                            <th>Caregiver Id</th>
                            <th>Patient Id</th>
                            <th></th>
                        </tr>
                        {loading &&
                            Array.from(Array(5).keys()).map((i) => (
                                <tr key={i}>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-3"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-1"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                </tr>
                            ))}
                        {data?.cases?.map((case_: Case) => (
                            <tr key={case_._id}>
                                <td>{case_.name}</td>
                                <td>{case_.ownerId}</td>
                                <td>{case_.caregiverId}</td>
                                <td>{case_.patientId}</td>
                                <td>
                                    <button
                                        className={'btn btn-sm btn-danger'}
                                        type={'button'}
                                        onClick={(_) => remove(case_._id, case_?.name ?? '')}
                                    >
                                        Delete with all data
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
