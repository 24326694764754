import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'

const httpLink = new HttpLink({
    uri: 'https://carelen.azurewebsites.net/graphql',
    // uri: 'http://localhost:4000/graphql',
})

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const rawToken = localStorage.getItem('authToken')
    const token = rawToken ? JSON.parse(rawToken) : rawToken // to remove quotes which useLocalStorage() adds

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    }
})

const cache = new InMemoryCache()
const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache,
})
export default client
