export const camelCaseToSentence = (str: string | null | undefined) => {
    return str
        ?.replace(/[A-Z]/g, (letter: string) => ` ${letter.toLowerCase()}`)
        .replace(/^(.)/, firstLetter => firstLetter.toUpperCase())
}

export const capitalize = (str?: string): string => {
    const trimmed = (str ?? '').trim()
    return trimmed.charAt(0).toUpperCase() + trimmed.slice(1)
}
