import {gql, useQuery} from '@apollo/react-hooks'
import {SimpleDate} from 'simple-date-ts'

type Props<TData> = {
    onCompleted?: (data: TData) => void
}

export const useUsersQuery = <TData = any>({onCompleted}: Props<TData>) =>
    useQuery(
        gql`
            query users {
                users {
                    _id
                    firstName
                    lastName
                    email
                    emailVerified
                    phoneNumber
                    roles
                    isAdmin
                    created_at
                    created_at_timestamp
                    updated_at
                    updated_at_timestamp
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
            onCompleted,
        },
    )
