// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.answersDashboardQuestionGroupTitle {
    color: #3F3C76;
    flex: 1 1;
    margin-left: 10px;
}

.answersDashboardAnswersContainer {
    display: flex;
    gap: 10px;
    flex-direction: row;
    flex-wrap: wrap;
}

.answersDashboardAnswerContainer {
    min-width: 200px;
    min-height: 200px;
    max-width: 500px;
    border-style: solid;
    border-color: lightgray;
    border-width: 1px;
    padding: 10px;
    background-color: white;
}

.answersDashboardQuestionTitle {
    color: #3F3C76;
}

.answersDashboardQuestionFrequency {
    flex: 1 1;
    font-size: 14px;
    margin-top: 8px;
    color: #70707B;
}
`, "",{"version":3,"sources":["webpack://./src/components/AnswersDashboard/AnswersDashboard.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,SAAO;IACP,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,SAAS;IACT,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,SAAO;IACP,eAAe;IACf,eAAe;IACf,cAAc;AAClB","sourcesContent":[".answersDashboardQuestionGroupTitle {\n    color: #3F3C76;\n    flex: 1;\n    margin-left: 10px;\n}\n\n.answersDashboardAnswersContainer {\n    display: flex;\n    gap: 10px;\n    flex-direction: row;\n    flex-wrap: wrap;\n}\n\n.answersDashboardAnswerContainer {\n    min-width: 200px;\n    min-height: 200px;\n    max-width: 500px;\n    border-style: solid;\n    border-color: lightgray;\n    border-width: 1px;\n    padding: 10px;\n    background-color: white;\n}\n\n.answersDashboardQuestionTitle {\n    color: #3F3C76;\n}\n\n.answersDashboardQuestionFrequency {\n    flex: 1;\n    font-size: 14px;\n    margin-top: 8px;\n    color: #70707B;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
