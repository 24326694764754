import {gql, useQuery} from '@apollo/react-hooks'
import {SimpleDate} from 'simple-date-ts'
import GraphQlHookProps from './GraphQlHookProps'
import { Note } from '../gql/graphql'

export const useNotesQuery = <TData = any>({variables, onCompleted}: GraphQlHookProps<{notes: Note[]}>) =>
    useQuery(
        gql`
            query notes($input: NotesInput!) {
                notes(input: $input) {
                    _id
                    text
                    answerId
                    authorId
                    author {
                        firstName
                        lastName
                        imageUrl
                    }
                    created_at
                    created_at_timestamp
                    updated_at
                    updated_at_timestamp
                    date
                    audioUrl
                    caseId
                    answerId
                    text
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
            onCompleted,
            variables
        },
    )
