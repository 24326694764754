import {gql, useQuery} from '@apollo/react-hooks'
import {SimpleDate} from 'simple-date-ts'
import { Case } from '../gql/graphql'

type Props<TData> = {
    onCompleted?: (data: TData) => void
}

export const useCasesQuery = <TData = any>({onCompleted}: Props<{cases: Case[]}>) =>
    useQuery(
        gql`
            query cases {
                cases {
                    _id
                    name
                    caregiverId
                    created_at
                    ownerId
                    updated_at
                    updated_at_timestamp
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
            onCompleted,
        },
    )
