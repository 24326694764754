import {gql, useMutation} from '@apollo/react-hooks'
import GraphQlHookProps from './GraphQlHookProps'
import {Note} from '../gql/graphql'

export const useRemoveCaseAndRelatedDataMutation = ({onCompleted, onError}: GraphQlHookProps<{createNote?: Note}>) => {
    const mutation = gql`
        mutation removeCaseAndRelatedData($id: String!) {
            removeCaseAndRelatedData(id: $id) {
                message
            }
        }
    `

    return useMutation(mutation, {
        onCompleted,
        onError,
    })
}
