import {gql, useQuery} from '@apollo/react-hooks'
import {SimpleDate} from 'simple-date-ts'
import GraphQlHookProps from './GraphQlHookProps'
import { DailyQuestionsOutput } from '../gql/graphql'

export const useDailyQuestionsQuery = <TData = any>({variables, onCompleted, onError}: GraphQlHookProps<{dailyQuestions: DailyQuestionsOutput}>) =>
    useQuery(
        gql`
            query dailyQuestions($input: DailyQuestionsInput!) {
                dailyQuestions(input: $input) {
                    sections {
                        title
                        questions {
                            _id
                            questionId
                            caseId
                            title
                            order
                            logTimes
                            logWeekdays
                            targetDate
                            targetTime
                            frequency
                            customFrequency
                            priority
                            methodsOfGathering
                            answerOptions
                            order
                            considerations
                            isCompleted
                            answer {
                                _id
                                caseId
                                questionId
                                caregiverId
                                targetTime
                                targetDate
                                text
                                emotion
                                selectedOptions
                                photoBlobNames
                                audioBlobNames
                                videoBlobNames
                            }
                        }
                    }
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
            variables,
            onCompleted,
            onError,
        },
    )
