import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../hooks/useAuth'
import './TopMenu.css'
import { useProfileQuery } from '../../api/useProfileQuery'

type Props = {}

export const TopMenu: React.FC<Props> = ({}) => {
    const navigate = useNavigate()

    const { setAuthToken } = useAuth()

    const logout = () => {
        setAuthToken(undefined)
        navigate('/login')
    }

    const { data, loading } = useProfileQuery({})
    const profile = data?.profile

    return (
        <div className={'topMenu'}>
            <span className={'topMenuLogo'}>CareLen</span>
            <div className={'d-flex flex-grow-1 align-items-center'}>
                <a className={'topMenuItem'} href={'/'}>
                    Dashboard
                </a>
                <a className={'topMenuItem'} href={'users'}>
                    Users
                </a>
                <a className={'topMenuItem'} href={'cases'}>
                    Cases
                </a>
            </div>

            <div className={'d-flex flex-row align-items-center'}>
                <span className={'topMenuItem'}>
                    {profile?.firstName} {profile?.lastName}
                </span>
                <a className={'topMenuItem'} href={'#'} onClick={logout}>
                    Logout
                </a>
            </div>
        </div>
    )
}
