import React from 'react'
import { SimpleDate } from 'simple-date-ts'
import './DateNavigator.css'

type Props = {
    date: SimpleDate
    setDate: (date: SimpleDate) => void
}

const DateNavigator: React.FC<Props> = ({ date, setDate }) => {
    const goBack = () => {
        setDate(date.previousDay())
    }

    const goForward = () => {
        setDate(date.nextDay())
    }

    return (
        <h2 style={{}}>
            <div className={'dateNavigator'} style={{ display: 'flex', flexDirection: 'row', margin: 'auto' }}>
                <button
                    type={'button'}
                    onClick={goBack}
                    className={'btn btn-sm btn-outline border-secondary dateNavigatorButton rounded-3'}
                >
                    ◂
                </button>
                <div
                    style={{
                        textAlign: 'center',
                        flex: 1,
                        marginLeft: 20,
                        marginRight: 20,
                    }}
                >
                    {date.toJsDate().toLocaleDateString('en-us', {
                        weekday: 'short',
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                    })}
                </div>

                <button
                    type={'button'}
                    onClick={goForward}
                    className={'btn btn-sm btn-outline border-secondary dateNavigatorButton rounded-3'}
                >
                    ▸
                </button>
            </div>
        </h2>
    )
}

export default DateNavigator
