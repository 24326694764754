import React, { useState } from 'react'
import { gql, useMutation } from '@apollo/react-hooks'
import type { ApolloError } from '@apollo/client/errors'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/useAuth'

// noinspection GraphQLUnresolvedReference
const SIGN_IN = gql`
    mutation signIn($signInInput: SignInInput!) {
        signIn(signInInput: $signInInput) {
            token
        }
    }
`

type SignInFormProps = {}

export default function SignInForm({}: SignInFormProps) {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [error, setError] = useState<ApolloError | undefined>()

    const { authToken, setAuthToken } = useAuth()

    const navigate = useNavigate()

    const [signIn, response] = useMutation(SIGN_IN, {
        variables: {
            signInInput: { email, password, phoneNumber: '' },
        },
        onCompleted: (data) => {
            let token = data.signIn.token

            if (token) {
                setError(undefined)
                setAuthToken?.(token)
                navigate('/')
            } else {
                setError({
                    cause: undefined,
                    clientErrors: [],
                    extraInfo: undefined,
                    graphQLErrors: [],
                    name: '',
                    networkError: null,
                    protocolErrors: [],
                    stack: '',
                    message: JSON.stringify(data),
                })
            }
        },
        onError: (error) => {
            setError(error)
        },
    })

    if (error) {
        return <div>{error.message}</div>
    }

    return (
        <div style={{ margin: 'auto', width: 200 }}>
            <h1>Login</h1>
            <br />
            <br />
            Email
            <br />
            <input
                type="email"
                value={email}
                onInput={
                    // @ts-ignore
                    (event: React.FormEvent<HTMLInputElement>) => setEmail(event.target.value)
                }
            />
            <br />
            <br />
            Password
            <br />
            <input
                type="password"
                value={password}
                onInput={
                    // @ts-ignore
                    (event: React.FormEvent<HTMLInputElement>) => setPassword(event.target.value)
                }
            />
            <br />
            <br />
            <button onClick={() => signIn()}>Login</button>
        </div>
    )
}
