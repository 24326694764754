// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.topMenu {
    background-color: black;
    display: flex;
    flex-direction: row;
    gap: 30px;
    margin-bottom: 10px;
    padding: 5px;
    text-align: left;
    font-size: 48px;
}

.topMenuLogo {
    color: #8d82ff;
    margin-top: -10px;
}

.topMenuItem {
    font-size: 24px;
    margin-left: 20px;
}

.topMenuItem, .topMenuItem:any-link {
    color: white;
    text-decoration: none;
}

`, "",{"version":3,"sources":["webpack://./src/components/TopMenu/TopMenu.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,aAAa;IACb,mBAAmB;IACnB,SAAS;IACT,mBAAmB;IACnB,YAAY;IACZ,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,qBAAqB;AACzB","sourcesContent":[".topMenu {\n    background-color: black;\n    display: flex;\n    flex-direction: row;\n    gap: 30px;\n    margin-bottom: 10px;\n    padding: 5px;\n    text-align: left;\n    font-size: 48px;\n}\n\n.topMenuLogo {\n    color: #8d82ff;\n    margin-top: -10px;\n}\n\n.topMenuItem {\n    font-size: 24px;\n    margin-left: 20px;\n}\n\n.topMenuItem, .topMenuItem:any-link {\n    color: white;\n    text-decoration: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
