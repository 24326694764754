import {gql, useQuery} from '@apollo/react-hooks'
import type {ApolloError} from '@apollo/client/errors'

type Props<TData = any> = {
    onCompleted?: (data: TData) => void
    onError?: (error: ApolloError) => void
}

export const useProfileQuery = <TData = any>({onCompleted, onError}: Props<TData> = {}) =>
    useQuery(
        gql`
            query profile {
                profile {
                    firstName
                    lastName
                    roles
                    email
                    emailVerified
                    phoneNumber
                    imageUrl
                }
            }
        `,
        {
            fetchPolicy: 'no-cache',
            onCompleted,
            onError,
        },
    )
