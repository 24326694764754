// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dateNavigator {

}

.dateNavigator div {
    color: #3F3C76;

}

.dateNavigatorButton {
    font-size: 24px;
    margin: 0;
    /*padding-top: 0;*/
}
`, "",{"version":3,"sources":["webpack://./src/components/DateNavigator/DateNavigator.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,cAAc;;AAElB;;AAEA;IACI,eAAe;IACf,SAAS;IACT,kBAAkB;AACtB","sourcesContent":[".dateNavigator {\n\n}\n\n.dateNavigator div {\n    color: #3F3C76;\n\n}\n\n.dateNavigatorButton {\n    font-size: 24px;\n    margin: 0;\n    /*padding-top: 0;*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
