import React from 'react'
import { useCasesQuery } from '../../api/useCasesQuery'
import { Case } from '../../gql/graphql'
import './CaseSelect.css'

type Props = {
    onSelect: (caseId: string) => void
}

export const CaseSelect: React.FC<Props> = ({ onSelect }) => {
    const { data, loading } = useCasesQuery({
        onCompleted: (data) => {
            const caseId = data.cases.length > 0 ? data.cases[0]._id : null
            if (caseId) {
                onSelect(caseId)
            }
        },
    })

    return (
        <div style={{ textAlign: 'right', color: '#3F3C76', textWrap: 'nowrap' }}>
            {loading && (
                <div style={{ display: 'inline-block' }}>
                    <h2 className="card-text placeholder-glow">
                        <span className="placeholder col-12"></span>
                    </h2>
                </div>
            )}
            {!loading && (
                <div style={{ textWrap: 'nowrap', marginRight: 100 }}>
                    <h2 className={'d-inline-block'}>Case: </h2>
                    &nbsp;
                    <select
                        className={'form-select form-select-lg caseSelectSelect border-secondary'}
                        onChange={(event) => onSelect(event.target.selectedOptions[0].value)}
                    >
                        {data?.cases?.map((case_: Case) => (
                            <option key={case_._id} value={case_._id}>
                                {case_.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}
        </div>
    )
}
