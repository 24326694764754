import React, { useState } from 'react'
import { User } from '../../gql/graphql'
import { useUsersQuery } from '../../api/useUsersQuery'
import { TopMenu } from '../TopMenu/TopMenu'
import { useRemoveCaseAndRelatedDataByOwnerUserIdMutation } from '../../api/useRemoveCaseAndRelatedDataByOwnerUserIdMutation'
import { useRemoveUserMutation } from '../../api/useRemoveUserMutation'

type Props = {
    authToken: string | null
    setAuthToken: (token: string | null) => void
}

export const UserList: React.FC<Props> = () => {
    const { data, loading, refetch } = useUsersQuery({})

    const [reloadParam, setReloadParam] = useState(0)

    const [removeAllCasesByOwnerUserId] = useRemoveCaseAndRelatedDataByOwnerUserIdMutation({
        variables: { reloadParam },
        onCompleted: (data) => {
            alert(data?.removeCaseAndRelatedDataByOwnerUserId?.message)
        },
    })

    async function doRemoveAllCasesByUserId(_id: string, firstName: string, lastName: string) {
        if (!window.confirm(`Are you sure you want to delete all cases owned by the user ${firstName} ${lastName}?`)) {
            return
        }

        await removeAllCasesByOwnerUserId({
            variables: { userId: _id },
        })
    }

    const [removeUser] = useRemoveUserMutation({
        variables: { reloadParam },
        onCompleted: (data) => {
            const message = data?.removeUser?.message
            if (message != 'ok') {
                alert(message)
            }
        },
    })

    async function doRemoveUser(_id: string, firstName: string, lastName: string) {
        if (!window.confirm(`Are you sure you want to delete the user ${firstName} ${lastName}?`)) {
            return
        }

        await removeUser({
            variables: { id: _id },
        })
        setReloadParam(reloadParam + 1) // forcing reload, otherwise apollo won't reload anything
        await refetch()
    }

    return (
        <>
            <TopMenu />

            <div className="content">
                <h1>Users</h1>

                <table className={'table table-bordered'}>
                    <tbody>
                        <tr>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Email Verified</th>
                            <th>Phone</th>
                            <th>Roles</th>
                            <th>Is Admin</th>
                            <th>Id</th>
                            <th></th>
                        </tr>
                        {loading &&
                            Array.from(Array(5).keys()).map((i) => (
                                <tr key={i}>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-3"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-1"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-1"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-12"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-6"></span>
                                        </p>
                                    </td>
                                    <td>
                                        <p className="card-text placeholder-glow">
                                            <span className="placeholder bg-secondary col-12"></span>
                                        </p>
                                    </td>
                                </tr>
                            ))}

                        {data?.users?.map((user: User) => (
                            <tr key={user._id}>
                                <td>{user.firstName}</td>
                                <td>{user.lastName}</td>
                                <td>{user.email}</td>
                                <td>{user.emailVerified}</td>
                                <td>{user.phoneNumber}</td>
                                <td>{user.roles?.join(', ')}</td>
                                <td className={'text-center'}>{user.isAdmin ? '✅' : ''}</td>
                                <td>{user._id}</td>
                                <td>
                                    <button
                                        className={'btn btn-sm btn-danger'}
                                        type={'button'}
                                        onClick={(_) =>
                                            doRemoveUser(user._id, user?.firstName ?? '', user?.lastName ?? '')
                                        }
                                    >
                                        Delete
                                    </button>
                                    &nbsp;
                                    <button
                                        className={'btn btn-sm btn-warning'}
                                        type={'button'}
                                        onClick={(_) =>
                                            doRemoveAllCasesByUserId(
                                                user._id,
                                                user?.firstName ?? '',
                                                user?.lastName ?? '',
                                            )
                                        }
                                    >
                                        Delete Owned Cases
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>
    )
}
