import React, { useState } from 'react'
import { Note } from '../../gql/graphql'
import { useNotesQuery } from '../../api/useNotesQuery'
import { SimpleDate } from 'simple-date-ts'
import { ApolloError } from '@apollo/client/errors'
import './VoiceNoteDashboard.css'

type Props = {
    caseId: string
    date: SimpleDate
}

export const VoiceNoteDashboard: React.FC<Props> = ({ caseId, date }: Props) => {
    const [error, setError] = useState<ApolloError>()

    const {
        data: notesData,
        loading: loadingNotes,
        refetch: refetchNotes,
    } = useNotesQuery({
        variables: {
            input: {
                caseId,
                date: date.getRaw(),
            },
        },
        onError: (error) => setError(error),
    })

    if (loadingNotes) {
        return (
            <div className={'voiceNotesContainer'}>
                {Array.from(Array(5).keys()).map((i) => (
                    <div key={i} className={'voiceNotesContainer'}>
                        <div className={'voiceNoteContainer'}>
                            <div className={'voiceNoteHeader'}>
                                <div
                                    className={'voiceNoteAuthorPhoto voiceNoteAuthorPhotoPlaceholder placeholder-glow'}
                                ></div>
                                <div style={{ flex: 1 }}>
                                    <p className="card-text placeholder-glow">
                                        <span className="placeholder bg-secondary col-12"></span>
                                        <span className="placeholder bg-secondary col-4"></span>
                                    </p>
                                </div>
                            </div>
                            <p className="card-text placeholder-glow">
                                <span className="placeholder bg-secondary col-7"></span>
                                <span className="placeholder bg-secondary col-4"></span>
                                <span className="placeholder bg-secondary col-4"></span>
                                <span className="placeholder bg-secondary col-6"></span>
                                <span className="placeholder bg-secondary col-8"></span>
                            </p>
                        </div>
                    </div>
                ))}
            </div>
        )
    }

    return (
        <div className={'voiceNotesContainer'}>
            {notesData?.notes.length === 0 && (
                <div className={'voiceNoteNothingFound'}>
                    <p>No notes for this day</p>
                </div>
            )}

            {notesData?.notes.map((note: Note, index: number) => {
                const createdAt = new Date(parseInt(note.created_at ?? '0'))

                return (
                    <div key={index} className={'voiceNoteContainer'}>
                        <div className={'voiceNoteHeader'}>
                            <img
                                className={'voiceNoteAuthorPhoto'}
                                src={note.author?.imageUrl ?? ''}
                                alt={note.author?.firstName ?? ''}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div
                                    style={{
                                        backgroundColor: '#F4F1FF',
                                        borderRadius: 1000,
                                        paddingTop: 4,
                                        paddingBottom: 4,
                                        paddingLeft: 8,
                                        paddingRight: 8,
                                    }}
                                >
                                    <div style={{ color: '#6E68DB' }}>{note.author?.firstName}</div>
                                </div>
                                <div style={{ color: 'gray' }}>
                                    Logged at {createdAt.getHours() % 12}
                                    {createdAt.getHours() >= 12 ? 'PM' : 'AM'}
                                </div>
                            </div>
                        </div>

                        <br />

                        <div style={{ overflow: 'hidden', flexShrink: 1 }}>{note.text}</div>
                    </div>
                )
            })}
        </div>
    )
}
