import { SimpleDate } from 'simple-date-ts'
import { DailyQuestion, DailyQuestionSection } from '../../gql/graphql'
import { useDailyQuestionsQuery } from '../../api/useDailyQuestionsQuery'
import { camelCaseToSentence } from '../../utils/stringUtils'
import React from 'react'
import './AnswersDashboard.css'

type Props = {
    caseId: string
    date: SimpleDate
}

export const AnswersDashboard: React.FC<Props> = ({ caseId, date }: Props) => {
    const { data, loading, refetch } = useDailyQuestionsQuery({
        variables: {
            input: {
                caseId,
                date: date.getRaw(),
            },
        },
    })

    if (loading) {
        return (
            <>
                <h3 className={'answersDashboardQuestionGroupTitle'}>
                    <p className="card-text placeholder-glow">
                        <span className="placeholder bg-secondary col-3"></span>
                    </p>
                </h3>

                <div className={'answersDashboardAnswersContainer'}>
                    {Array.from(Array(5).keys()).map((i) => (
                        <div key={i} className={'answersDashboardAnswerContainer'}>
                            <h4 className={'answersDashboardQuestionTitle'}>
                                <p className="card-text placeholder-glow">
                                    <span className="placeholder bg-secondary col-12"></span>
                                </p>
                            </h4>

                            <div className={'answersDashboardQuestionFrequency'}>
                                <p className="card-text placeholder-glow">
                                    <span className="placeholder bg-secondary col-7"></span>
                                </p>
                            </div>

                            <br />

                            <div>
                                <p className="card-text placeholder-glow">
                                    <span className="placeholder bg-secondary col-12"></span>
                                    <span className="placeholder bg-secondary col-8"></span>
                                    <span className="placeholder bg-secondary col-4"></span>
                                    <span className="placeholder bg-secondary col-11"></span>
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </>
        )
    }

    const renderQuestion = (question: DailyQuestion, index: number) => (
        <div key={index} className={'answersDashboardAnswerContainer'}>
            <h4 className={'answersDashboardQuestionTitle'}>{question.title}</h4>

            {question.priority == 'high' && <div>High Priority</div>}

            <div className={'answersDashboardQuestionFrequency'}>
                {camelCaseToSentence(question.frequency)} {question.targetTime}
            </div>

            <br />

            {!question.isCompleted && <div style={{ color: 'grey', paddingTop: 10 }}>Pending</div>}
            {question.isCompleted && <div>{question.answer?.text}</div>}
        </div>
    )

    const renderSection = (section: DailyQuestionSection, index: number) => {
        if (section.questions.length === 0) {
            return <></>
        }

        return (
            <div key={index}>
                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 30, paddingTop: 10 }}>
                    <h3 className={'answersDashboardQuestionGroupTitle'}>
                        {section.title} ({section.questions.length})
                    </h3>
                </div>

                <div className={'answersDashboardAnswersContainer'}>
                    {section.questions?.map((question: DailyQuestion, index: number) =>
                        renderQuestion(question, index),
                    )}
                </div>
            </div>
        )
    }

    return (
        <>
            {data?.dailyQuestions?.sections.map((section: DailyQuestionSection, index: number) =>
                renderSection(section, index),
            )}
        </>
    )
}
